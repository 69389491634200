import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Container, Row, Col, Form, Button, Table, Card, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSearch } from "react-icons/fa";

const SearchWarranty = () => {
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    customerName: "",
    trans: "",
    location: "",
  });

  const [searchResults, setSearchResults] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.startDate &&
      !formData.endDate &&
      !formData.customerName &&
      !formData.trans &&
      !formData.location
    ) {
      setSearchResults([]);
      setSearchMessage("Please provide at least one search filter.");
      return;
    }

    setLoading(true);

    try {
      const formattedStartDate = formData.startDate ? formData.startDate.toISOString().split("T")[0] : "";
      const formattedEndDate = formData.endDate ? formData.endDate.toISOString().split("T")[0] : "";

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/search_warranties/`, {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          customerName: formData.customerName,
          trans: formData.trans,
          location: formData.location,
        },
      });

      setLoading(false);

      if (response.data.length === 0) {
        setSearchResults([]);
        setSearchMessage("This customer does not have any warranty.");
      } else {
        setSearchResults(response.data);
        setSearchMessage("");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setSearchResults([]);
      setSearchMessage("This customer does not have any warranty.");
    }
  };

  return (
    <Container fluid className="p-4" style={{ background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)", minHeight: "100vh" }}>
      <Row className="mb-3">
        <Col md={{ span: 8, offset: 2 }}>
          <Card className="shadow-lg border-0" style={{ borderRadius: "15px" }}>
            <Card.Body style={{ background: "#f8f9fa", borderRadius: "15px" }}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label><b>Start Date:</b> 📅</Form.Label>
                      <DatePicker
                        selected={formData.startDate}
                        onChange={(date) => handleDateChange(date, "startDate")}
                        className="form-control mt-4"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label><b>End Date:</b> 📅</Form.Label>
                      <DatePicker
                        selected={formData.endDate}
                        onChange={(date) => handleDateChange(date, "endDate")}
                        className="form-control  mt-4"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label><b>Customer Name:</b></Form.Label>
                      <Form.Control
                        type="text"
                        name="customerName"
                        value={formData.customerName}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label><b>Transaction Number:</b></Form.Label>
                      <Form.Control
                        type="text"
                        name="trans"
                        value={formData.trans}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label><b>Location:</b></Form.Label>
                      <Form.Control
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="d-flex align-items-end justify-content-end">
                    <Button type="submit" variant="primary" className="w-100">
                      {loading ? <Spinner animation="border" size="sm" /> : <><FaSearch /> Search</>}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <div className="mt-4">
            <h4><b>Search Results</b></h4>
            <Table striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
              <thead style={{ backgroundColor: "#007bff", color: "#ffffff" }}>
                <tr>
                <th>Customer ID</th>
                  <th>Customer Name</th>
                  <th>Transaction Number</th>
                  <th>Transaction SO Number</th>
                  <th>Store</th>
                  <th>Product Model No</th>
                  <th>Warranty Date Sold</th>
                  <th>Warranty Model No</th>
                  <th>QTY</th>
                  <th>Warranty Price</th>
                  <th>Address</th>
                  <th>Brand</th>
                  <th>Warranty Expiry Date</th>
                  <th>Days left in warranty</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result, index) => (
                  <tr key={index}>
                    <td>{result.customer_id}</td>
                    <td>{result.name}</td>
                    <td>{result.transaction_number}</td>
                    <td>{result.s_trans_so}</td>
                    <td>{result.loc}</td>
                    <td>{result.model_number}</td>
                    <td>{result.date_sale}</td>
                    <td>{result.warranty_type}</td>
                    <td>{result.Quantity}</td>
                    <td>{result.warranty_price}</td>
                    <td>{result.address}</td>
                    <td>{result.brand}</td>
                    <td>{result.warranty_expiry_date}</td>
                    <td>{result.days_left}</td>
                  </tr>
                ))}
                {searchResults.length === 0 && (
                  <tr>
                    <td colSpan="10" className="text-center" style={{ fontWeight: "bold" }}>
                      {searchMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchWarranty;
