import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { Button, Table, Form, Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

const Reports = () => {
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportData, setReportData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [isUpdatedReport, setIsUpdatedReport] = useState(false); // New state to toggle between created_date and updated_date report

  const recordsPerPage = 20;
  const pagesVisited = pageNumber * recordsPerPage;

  const handleSearch = async (apiUrl) => {
    try {
      const params = {};
      if (status) {
        params.status = status;
      }
      if (startDate && endDate) {
        params.start_date = startDate;
        params.end_date = endDate;
      }

      const response = await axios.get(apiUrl, { params });
      console.log('The data coming from the backend is', response.data);

      const data = response.data || [];
      setReportData(data);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Error generating report. Please try again.');
    }
  };

  // Function to export the report to Excel
  const downloadExcel = () => {
    if (reportData.length === 0) {
      setErrorMessage('No data to download.');
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  const displayRecords = reportData.slice(pagesVisited, pagesVisited + recordsPerPage);
  const pageCount = Math.ceil(reportData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="w-100 px-4" style={{ maxWidth: '100vw' }}>
      <h2 className="my-4">
        Generate Reports
        <br />
        <small>{isUpdatedReport ? 'Report is based on the updated_date' : 'Report is based on the created_date'}</small>
      </h2>

      <Form>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">Select Status</option>
                <option value="Pending">Pending</option>
                {/* <option value="In Progress">In Progress</option> */}
                <option value="Declined">Declined</option>
                <option value="Repair in Progress">Repair in Progress</option>
                <option value="Repaired">Repaired</option>
                <option value="Replaced/Credit Issued">Replaced/Credit Issued</option>
                <option value="Pending Manager Approval">Pending Manager Approval</option>
                <option value="Archive">Archive</option>
                <option value="Closed">Closed</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" onClick={() => handleSearch(`${process.env.REACT_APP_BACKEND_URL}/api/generate_report/`)}>Generate Report (Created Date)</Button>
        <Button
          variant="secondary"
          className="ml-3"
          onClick={() => {
            setIsUpdatedReport(true);
            handleSearch(`${process.env.REACT_APP_BACKEND_URL}/api/update_date_report/`);
          }}
        >
          Generate Report (Updated Date)
        </Button>
        {reportData.length > 0 && (
          <Button variant="success" className="ml-3" onClick={downloadExcel}>
            Download Excel
          </Button>
        )}
      </Form>

      {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}

      {displayRecords.length > 0 ? (
        <>
          <Table striped bordered hover responsive className="mt-4 w-100">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zipcode</th>
                <th>Phone Number</th>
                <th>Brand</th>
                <th>Transaction Number</th>
                <th>Store</th>
                <th>Purchase Date</th>
                <th>Model Number</th>
                <th>Price Product</th>
                <th>Price Warranty</th>
                <th>Internal Status</th>
                <th>Warranty ID</th>
                <th>Department ID</th>
                <th>Money Spent</th>
              </tr>
            </thead>
            <tbody>
              {displayRecords.map((report) => (
                <tr key={report.id}>
                  <td>{report.first_name}</td>
                  <td>{report.last_name}</td>
                  <td>{report.address}</td>
                  <td>{report.city}</td>
                  <td>{report.state}</td>
                  <td>{report.zipcode}</td>
                  <td>{report.phone_number}</td>
                  <td>{report.brand}</td>
                  <td>{report.transaction_number}</td>
                  <td>{report.store}</td>
                  <td>{report.purchase_date}</td>
                  <td>{report.model_number}</td>
                  <td>{report.price_product}</td>
                  <td>{report.price_warranty}</td>
                  <td>{report.internal_status}</td>
                  <td>{report.warranty_id}</td>
                  <td>{report.department_id}</td>
                  <td>{report.money_spent}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-center mt-3">
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'pagination pagination-lg'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
              disabledClassName={'disabled'}
            />
          </div>
        </>
      ) : (
        <p className="mt-3">No report data available.</p>
      )}
    </div>
  );
};

export default Reports;
