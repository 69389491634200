import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dispatch = () => {
    const location = useLocation();
    // const history = useHistory();
    const [showForm, setShowForm] = useState(false);
    const [dispatchTrackSchedule, setDispatchTrackSchedule] = useState([]);
    const [formData, setFormData] = useState({
        trackingId: '',
        deliveryDate: '',
        requestDeliveryDate: '',
        servicetype: 'Delivery',
        description: '',
        // employee_description: '',
        customer_description:'',
        firstName: '',
        lastName: '',
        email: '',
        phone1: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        itemId: '',
        itemDescription: '',
        quantity: '',
        serviceUnit: '',
        delivery_time_window_start: '',
        delivery_time_window_end: '',
        request_time_window_start: '',
        request_time_window_end: ''
    });

    const getCurrentTime = () => {
        const now = new Date();
        return now.toTimeString().split(' ')[0]; // Format to HH:MM:SS
    };

    useEffect(() => {
        if (location.state && location.state.claim) {
            const { claim } = location.state;
            const [firstName, ...lastNameParts] = claim.full_name.split(' ');
            const lastName = lastNameParts.join(' ');

            setFormData((prevData) => ({
                ...prevData,
                firstName,
                lastName,
                email: claim.email,
                phone1: claim.phone_number,
                address1: claim.address1 || claim.address,
                city: claim.city || '',
                state: claim.state || '',
                zip: claim.zipcode || claim.zip || '',
                itemId: claim.model_number,
                quantity: claim.qty || '1',
                description: claim.employee_description || '',  
                employee_description: claim.employee_description || '',
                customer_description:  claim.customer_description || ''
            }));

            // Fetch dispatch track schedule from the API
            fetchDispatchTrackSchedule(claim.id); // Assuming claim.id is the customer_request_id
        }
    }, [location.state]);

    const fetchDispatchTrackSchedule = async (customerRequestId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get_dispatch_track_schedule/${customerRequestId}/`);
            setDispatchTrackSchedule(response.data);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setDispatchTrackSchedule([]);
            } else {
                console.error('Error fetching dispatch track schedule', error);
                toast.error('Error fetching dispatch track schedule.');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date, name) => {
        setFormData({ ...formData, [name]: date });
    };

    const handleTimeFocus = (e) => {
        const { name } = e.target;
        if (!formData[name]) {
            setFormData({ ...formData, [name]: getCurrentTime() });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Check for required fields
        const requiredFields = [
            'servicetype', 'description', 'firstName', 'lastName', 
            'email', 'phone1', 'address1', 'city', 'state', 'zip', 
            'itemId', 'quantity', 'serviceUnit', 'deliveryDate'
        ];
        for (let field of requiredFields) {
            if (!formData[field]) {
                alert('Please fill in all required fields.');
                return;
            }
        }

        const claimId = location.pathname.split('/').pop();  // Extract claimId from the URL
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create_dispatch_track_schedule/${claimId}/`, {
                service_type: formData.servicetype,
                description: formData.customer_description,
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                phone1: formData.phone1,
                address1: formData.address1,
                city: formData.city,
                state: formData.state,
                zip: formData.zip,
                item_id: formData.itemId,
                item_description: formData.employee_description,
                number: formData.number,
                quantity: formData.quantity,
                service_unit: formData.serviceUnit,
                delivery_date: formData.deliveryDate,
                request_delivery_date: formData.requestDeliveryDate,
                delivery_time_window_start: formData.delivery_time_window_start,
                delivery_time_window_end: formData.delivery_time_window_end,
                request_time_window_start: formData.request_time_window_start,
                request_time_window_end: formData.request_time_window_end,
                nonce: formData.nonce
            });
            console.log(response.data);
            toast.success('The form has been submitted and the details have been passed to the dispatch track portal.');

            // Redirect to the table view after submission
            setShowForm(false);
            fetchDispatchTrackSchedule(claimId);  // Refresh the table with the new data
        } catch (error) {
            console.error('Error submitting the form', error);
            toast.error('Error submitting the form.');
        }
    };

    const largerFont = { fontSize: '18px' };

    return (
        <div className="container">
            <ToastContainer />
            <h2 className="text-center" style={largerFont}>Dispatch Track List</h2>
            <div className="table-responsive">
                {!showForm && (
                    <table className="table table-bordered table-hover" style={{ fontSize: "18px" }}>
                        <thead className="thead-dark">
                            <tr>
                                <th>Dispatch Tracking ID</th>
                                <th>Delivery Date</th>
                                <th>Request Delivery Date</th>
                                <th>Created Date</th>
                                <th>Updated Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dispatchTrackSchedule.length > 0 ? (
                                dispatchTrackSchedule.map((schedule) => (
                                    <tr key={schedule.id}>
                                        <td>{schedule.id}</td>
                                        <td>{new Date(schedule.delivery_date).toLocaleDateString()}</td>
                                        <td>{new Date(schedule.request_delivery_date).toLocaleDateString()}</td>
                                        <td>{new Date(schedule.date_insert).toLocaleDateString()}</td>
                                        <td>{new Date(schedule.date_update).toLocaleDateString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No dispatch track schedule found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="mt-4">
                {!showForm ? (
                    <button className="btn btn-warning" onClick={() => setShowForm(true)} style={largerFont}>Schedule Repair</button>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <table className="table" style={largerFont}>
                            <tbody>
                                <tr>
                                    <td>Service type:<span style={{ color: 'red' }}>*</span></td>
                                    <td>
                                        <select className="form-control" name="servicetype" value={formData.servicetype} onChange={handleChange} required style={largerFont}>
                                            <option value="Delivery">Delivery</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </td>
                                    <td>Customer Description:<span style={{ color: 'red' }}>*</span></td>
                                    <td>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="description" 
                                            value={formData.customer_description} 
                                            onChange={handleChange} 
                                            required
                                            readOnly
                                            style={largerFont}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>First name:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="firstName" value={formData.firstName} onChange={handleChange} required style={largerFont} /></td>
                                    <td>Last name:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleChange} required style={largerFont} /></td>
                                </tr>
                                <tr>
                                    <td>Email:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required style={largerFont} /></td>
                                    <td>Phone1:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="phone1" value={formData.phone1} onChange={handleChange} required style={largerFont} /></td>
                                </tr>
                                <tr>
                                    <td>Address1:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="address1" value={formData.address1} onChange={handleChange} required style={largerFont} /></td>
                                    <td>City:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="city" value={formData.city} onChange={handleChange} required style={largerFont} /></td>
                                </tr>
                                <tr>
                                    <td>State:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="state" value={formData.state} onChange={handleChange} required style={largerFont} /></td>
                                    <td>Zip:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="zip" value={formData.zip} onChange={handleChange} required style={largerFont} /></td>
                                </tr>
                                <tr>
                                    <td>Item id:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="itemId" value={formData.itemId} onChange={handleChange} required style={largerFont} /></td>
                                </tr>
                                <tr>
                                    <td>Employee Notes (if missing please provide a employee note from customer detials page ):<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" required className="form-control" name="itemDescription" value={formData.employee_description} onChange={handleChange} style={largerFont} /></td>
                                    <td>Quantity:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="quantity" value={formData.quantity} onChange={handleChange} required style={largerFont} /></td>
                                </tr>
                                <tr>
                                    <td>Service unit:<span style={{ color: 'red' }}>*</span></td>
                                    <td><input type="text" className="form-control" name="serviceUnit" value={formData.serviceUnit} onChange={handleChange} required style={largerFont} /></td>
                                    <td>Delivery date:<span style={{ color: 'red' }}>*</span></td>
                                    <td>
                                        <DatePicker
                                            selected={formData.deliveryDate}
                                            onChange={(date) => handleDateChange(date, 'deliveryDate')}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            required
                                            style={largerFont}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Request delivery date:</td>
                                    <td>
                                        <DatePicker
                                            selected={formData.requestDeliveryDate}
                                            onChange={(date) => handleDateChange(date, 'requestDeliveryDate')}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            style={largerFont}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Delivery time window start:</td>
                                    <td>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="delivery_time_window_start"
                                            value={formData.delivery_time_window_start}
                                            onChange={handleChange}
                                            onFocus={handleTimeFocus}
                                            step="1"
                                            placeholder="HH:MM:SS"
                                            style={largerFont}
                                        />
                                    </td>
                                    <td>Delivery time window end:</td>
                                    <td>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="delivery_time_window_end"
                                            value={formData.delivery_time_window_end}
                                            onChange={handleChange}
                                            onFocus={handleTimeFocus}
                                            step="1"
                                            placeholder="HH:MM:SS"
                                            style={largerFont}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Request time window start:</td>
                                    <td>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="request_time_window_start"
                                            value={formData.request_time_window_start}
                                            onChange={handleChange}
                                            onFocus={handleTimeFocus}
                                            step="1"
                                            placeholder="HH:MM:SS"
                                            style={largerFont}
                                        />
                                    </td>
                                    <td>Request time window end:</td>
                                    <td>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="request_time_window_end"
                                            value={formData.request_time_window_end}
                                            onChange={handleChange}
                                            onFocus={handleTimeFocus}
                                            step="1"
                                            placeholder="HH:MM:SS"
                                            style={largerFont}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button type="submit" className="btn btn-primary" style={largerFont}>Submit</button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default Dispatch;

